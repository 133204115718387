import { EnviromentZone, IHowdenEnvironment, LogLevel } from '@howdeniberia/core-front';
import { howdenGroupAzureB2cProConfig } from './microsoft-b2c-howdengroup-pro';

export const environment = {
  zone: EnviromentZone.PROHOWDENGROUPB2C,
  appIdSecurity: 0,
  emailError: 'soporte@howdeniberia.com',
  logLevel: LogLevel.VERBOSE,
  openid: howdenGroupAzureB2cProConfig
} as IHowdenEnvironment;
