const howdenGroupAzureB2cProConfig = {
  msalConfig: {
    auth: {
      clientId: '73fec4df-89a2-4517-8126-4f5e5ea98d78',
      redirectUri: () => `${location.origin}/granted`
    }
  },
  apiConfig: {
    clientId: 'c705a900-e4ac-422c-bad5-739ecbb6d54e',
    scopes: ['https://hypxdev.onmicrosoft.com/web-api/webapi.read'],
    uri: 'https://hypxdev.onmicrosoft.com/web-api'
  },
  policies: {
    names: {
      signUpSignIn: 'b2c_1a_signup_signin_aad_howdeniberia',
      resetPassword: 'b2c_1a_password_reset_howdeniberia'
    },
    authorities: {
      signUpSignIn: {
        authority: 'https://hypxdev.b2clogin.com/hypxdev.onmicrosoft.com/b2c_1a_signup_signin_aad_howdeniberia'
      },
      resetPassword: {
        authority: 'https://hypxdev.b2clogin.com/hypxdev.onmicrosoft.com/b2c_1a_password_reset_howdeniberia'
      }
    },
    authorityDomain: 'hypxdev.b2clogin.com'
  },
  protectedResources: new Map([
    ['https://hypxdev.onmicrosoft.com/web-api', ['https://hypxdev.onmicrosoft.com/web-api/webapi.read']]
  ])
};

export { howdenGroupAzureB2cProConfig };
